import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import curator1 from "../assets/C11.png";
import curator2 from "../assets/C22.png";
import bannerImg from "../assets/3.jpg";

const curatorData = {
  en: [
    {
      name: "Kim Changjun",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "We are true professionals.",
      image: curator1,
    },
    {
      name: "Lee Kiseok",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "You can trust us.",
      image: curator2,
    },
  ],
  ko: [
    {
      name: "김창준",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "우리는 진짜 전문가입니다.",
      image: curator1,
    },
    {
      name: "이기석",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "믿고 오시면 됩니다.",
      image: curator2,
    },
  ],
  ja: [
    {
      name: "キム・チャンジュン",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "私たちは本物のプロフェッショナルです。",
      image: curator1,
    },
    {
      name: "イ・ギソ",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description: "信じてお任せください。",
      image: curator2,
    },
  ],
};

const quotes = {
  en: "“Rather than serving you through staff members, we, as representatives, personally accompany you to create the finest moments of your journey.”",
  ko: "“우리는 직원을 통해 고객님을 모시는 것이 아니라, 고객님께 최고의 순간을 선사하기 위해 대표자로서 직접 모시겠습니다.”",
  ja: "“私たちはスタッフを通じてではなく、お客様に最高の瞬間をお届けするため、代表者として直接ご案内いたします。”",
};

const PageContainer = styled.div`
  background: black;
  padding-bottom: 50px;
  min-height: 100vh;
`;

const BannerSection = styled.div`
  width: 100%;
  height: 300px;
  background: url(${bannerImg}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
`;

const CuratorContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

const CuratorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  margin-bottom: -50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
    margin-bottom: -40px;
  }
`;

const InfoBox = styled.div`
  width: 280px;
  padding: 50px 20px 30px;
  background: #3b5eb2;
  border-radius: 20px;
  color: white;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 40px 15px 25px;
  }
`;

const QuoteSection = styled.div`
  text-align: center;
  margin-top: 60px;
  font-style: italic;
  font-size: 1.3rem;
  color: white;
  padding: 0 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Playfair Display", serif;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Curator: React.FC = () => {
  const { language } = useLanguage();

  return (
    <PageContainer>
      <BannerSection>Meet the Curator</BannerSection>

      <CuratorContainer>
        {curatorData[language].map((curator, index) => (
          <CuratorCard key={index}>
            <ProfileImage src={curator.image} alt={curator.name} />
            <InfoBox>
              <p>{curator.country}</p>
              <h3>{curator.name}</h3>
              <p>{curator.title}</p>
              <p>{curator.description}</p>
            </InfoBox>
          </CuratorCard>
        ))}
      </CuratorContainer>

      <QuoteSection>{quotes[language]}</QuoteSection>
    </PageContainer>
  );
};

export default Curator;
