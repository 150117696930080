import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useLanguage } from "../contexts/LanguageContext";
import faqBanner from "../assets/1.jpg";

const faqData = {
  en: [
    {
      question: "How much luggage can I bring?",
      answer:
        "You can bring one large suitcase and one carry-on bag per person.",
    },
    {
      question: "Is there a dress code I need to be aware of for my tour?",
      answer:
        "There is no strict dress code, but we recommend comfortable attire suitable for travel.",
    },
    {
      question: "Lost Luggage Protection",
      answer:
        "We offer insurance options to protect against lost luggage during your trip.",
    },
  ],
  ko: [
    {
      question: "짐은 얼마나 가져갈 수 있나요?",
      answer:
        "1인당 대형 여행가방 1개와 기내 반입용 가방 1개를 가져갈 수 있습니다.",
    },
    {
      question: "여행 시 유의해야 할 복장 규정이 있나요?",
      answer:
        "엄격한 복장 규정은 없지만, 여행에 적합한 편안한 복장을 추천합니다.",
    },
    {
      question: "분실 수하물 보호 정책",
      answer: "여행 중 수하물 분실을 대비한 보험 옵션을 제공합니다.",
    },
  ],
  ja: [
    {
      question: "持ち込める荷物の量はどれくらいですか？",
      answer:
        "お一人様につき、大型スーツケース1つと機内持ち込み手荷物1つを持参できます。",
    },
    {
      question: "ツアーで注意すべきドレスコードはありますか？",
      answer:
        "特に厳格なドレスコードはありませんが、旅行に適した快適な服装をお勧めします。",
    },
    {
      question: "紛失荷物保護",
      answer: "旅行中の荷物紛失を補償する保険オプションをご提供しています。",
    },
  ],
};

const FAQsec = styled.div`
  background-color: black;
`;

const BannerSection = styled.div`
  width: 100%;
  height: 250px;
  background: url(${faqBanner}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
`;

const TittleText = styled.h2`
  color: white;
  margin-left: 20%;
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  margin-bottom: 0px;
  background: #1a1a1a;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
`;

const FAQItem = styled.div`
  background: #252525;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const QuestionButton = styled.button`
  width: 100%;
  padding: 15px;
  text-align: left;
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;

  &:hover {
    background: #333;
  }
`;

const Icon = styled.span`
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
`;

const AnswerContainer = styled(motion.div)`
  text-align: left;
  padding: 15px;
  font-size: 1rem;
  line-height: 1.6;
  background: #333;
  border-top: 1px solid #444;
`;

const FAQ: React.FC = () => {
  const { language } = useLanguage();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQsec>
      <BannerSection>Frequently Asked Questions</BannerSection>
      <br />
      <TittleText>Packing</TittleText>
      <FAQContainer>
        {faqData[language].map((item, index) => (
          <FAQItem key={index}>
            <QuestionButton onClick={() => toggleFAQ(index)}>
              {item.question}
              <Icon>{openIndex === index ? "−" : "+"}</Icon>
            </QuestionButton>
            <AnimatePresence>
              {openIndex === index && (
                <AnswerContainer
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {item.answer}
                </AnswerContainer>
              )}
            </AnimatePresence>
          </FAQItem>
        ))}
      </FAQContainer>
    </FAQsec>
  );
};

export default FAQ;
