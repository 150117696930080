import React from "react";
import { LanguageProvider } from "./contexts/LanguageContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import About from "./pages/About";
import Tours from "./pages/Tour";
import Contact from "./pages/Contact";
import Calendar from "./pages/Calendar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import FAQ from "./pages/FAQ";
import Ourstory from "./pages/OurStory";
import AboutTour from "./pages/AboutTour";
import Curator from "./pages/Curator";

const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/ourstory" element={<Ourstory />} />
          <Route path="/abouttour" element={<AboutTour />} />
          <Route path="/curator" element={<Curator />} />
        </Routes>
        <Footer />
      </Router>
    </LanguageProvider>
  );
};

export default App;
