import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import bg1 from "../assets/1-1.jpg";
import bg2 from "../assets/2-2.jpg";
import bg3 from "../assets/3-3.jpg";
import bg4 from "../assets/4-4.jpg";

type ContentType = {
  [key: string]: {
    title: string;
    description: string;
  };
};

const Ourstory = () => {
  const { language } = useLanguage();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const backgroundImages = [bg1, bg2, bg3, bg4];

  const content: ContentType = {
    ko: {
      title: "Our Story",
      description: `여행은 단순히 목적지에 도달하는 것이 아니라, 그 여정 자체가 소중한 경험이어야 한다고 믿습니다. 모멘토 코리아는 여행을 삶의 의미로 승화시키는 브랜드로서, 고객에게 세상에서 가장 특별하고 고급스러운 여행을 제공하고자 합니다.  우리의 이야기는 그 시작부터 끝까지, 편안함과 여유, 그리고 스타일을 더하는여정을 만들어가는 과정입니다.

모멘토, 매 순간을 소중한 추억으로 만들어드립니다. 최고급 리조트와 프라이빗한 투어, 그리고 맞춤형 서비스는 고객님이 진정한 휴식과 여유를 느낄 수 있도록 설계되었습니다. 우리는 여행이 단지 한 번의 경험이 아닌, 삶을 풍요롭게 만드는 과정이 되기를 바랍니다.

모멘토와 함께라면, 여행의 정의는 새롭게 쓰여집니다. 매 순간이 완벽하게 준비된 여정이 될 수 있도록,
우리는 늘 최고를 추구합니다. 이 여정에서 여러분만의 특별한 이야기를 만들어보세요.`,
    },
    en: {
      title: "Our Story",
      description: `We believe that travel is not just about reaching a destination, but that the journey itself should be a precious experience. As a brand that elevates travel into a meaningful part of life, Momento Korea aims to provide the most exceptional and luxurious journeys in the world.
Our story is about crafting a journey that adds comfort, relaxation, and style from beginning to end.

At Momento, we turn every moment into a cherished memory. With world-class resorts, private tours, and personalized services, we create an experience where you can truly relax and enjoy life at its finest. We believe that travel should not be just a single experience, but a journey that enriches your life.

With Momento, the definition of travel is rewritten. We always strive for excellence so that every moment becomes a perfectly curated journey.
Embark on this journey and create your own unique story.`,
    },
    ja: {
      title: "私たちの物語",
      description: `私たちは、旅とは単に目的地に到達することではなく、その旅自体が貴重な経験であるべきだと信じています。
モメントコリアは、旅を人生の意味へと昇華させるブランドとして、世界で最も特別で贅沢な旅行体験を提供することを目指しています。
私たちのストーリーは、最初から最後まで、快適さと余裕、そしてスタイルを加えた旅を作り上げる過程そのものです。

モメントは、一瞬一瞬を大切な思い出へと変えます。
最高級リゾート、プライベートツアー、オーダーメイドのサービスを通じて、お客様が心からリラックスし、贅沢な時間を満喫できるように設計されています。
私たちは、旅が単なる一度きりの経験ではなく、人生を豊かにするプロセスであることを願っています。

モメントとともに、旅の定義は新たに書き換えられます。
すべての瞬間が完璧に準備された旅となるよう、私たちは常に最高を追求します。
この旅の中で、あなただけの特別な物語を紡いでください。`,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prev) =>
        prev === backgroundImages.length - 1 ? 0 : prev + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [backgroundImages.length]);

  return (
    <Container>
      <ContentWrapper bgImage={backgroundImages[currentImageIndex]}>
        <Title>{content[language].title}</Title>
        <Description>{content[language].description}</Description>
      </ContentWrapper>
    </Container>
  );
};

export default Ourstory;

/* ------ styled-components ------ */
const Container = styled.div`
  min-height: 100vh;
  background: #000;
  color: #fff;
  padding: 120px 0;
`;

const ContentWrapper = styled.div<{ bgImage: string }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1.5s ease;
    animation: fadeIn 1.5s ease-in-out;
    opacity: 0.5;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(1.1);
    }
    to {
      opacity: 0.5;
      transform: scale(1);
    }
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  white-space: pre-line;
  color: #ccc;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;
