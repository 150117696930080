import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import img1 from "../assets/11.jpg";
import img2 from "../assets/6.jpg";
import img3 from "../assets/13.jpg";
import bannerImg from "../assets/14.jpg";

const tourData = {
  en: [
    {
      title: "Luxurious Accommodations",
      description:
        "Experience world-class luxury and comfort during your stay.",
      image: img1,
    },
    {
      title: "Outstanding Culinary Delights",
      description:
        "Indulge in gourmet dishes prepared by top chefs around the world.",
      image: img2,
    },
    {
      title: "Customized Adventures",
      description:
        "Tailored experiences that cater to your unique travel desires.",
      image: img3,
    },
  ],
  ko: [
    {
      title: "럭셔리한 숙박",
      description: "세계적인 수준의 럭셔리와 편안함을 경험하세요.",
      image: img1,
    },
    {
      title: "최고의 미식 경험",
      description: "세계 최고의 셰프들이 준비한 미식을 즐겨보세요.",
      image: img2,
    },
    {
      title: "맞춤형 모험",
      description: "여행자의 취향을 반영한 맞춤형 여행을 제공합니다.",
      image: img3,
    },
  ],
  ja: [
    {
      title: "豪華な宿泊施設",
      description: "世界クラスの贅沢と快適さを体験してください。",
      image: img1,
    },
    {
      title: "極上のグルメ体験",
      description: "一流シェフが作る美食を堪能しましょう。",
      image: img2,
    },
    {
      title: "カスタマイズされた冒険",
      description: "お客様の好みに合わせた特別な旅行をご提供します。",
      image: img3,
    },
  ],
};

const PageContainer = styled.div`
  background: black;
  padding-bottom: 50px;
`;

const BannerSection = styled.div`
  width: 100%;
  height: 300px;
  background: url(${bannerImg}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
`;

const TourContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const TourBlock = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  height: 350px;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 0;
  }
`;

const TourImage = styled.div<{ bgImage: string }>`
  flex: 1;
  background: url(${(props) => props.bgImage}) center/cover no-repeat;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileImageBackground = styled.div<{ bgImage: string }>`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.bgImage}) center/cover no-repeat;
    filter: brightness(0.5);
    z-index: 0;
  }
`;

const TourText = styled.div`
  flex: 1;
  background: #222;
  border: 2px solid white;
  color: white;
  padding: 0px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.1);
  text-align: center;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    width: 95%;
    min-height: 300px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    h2,
    p {
      color: white;
      text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
    }
  }
`;

const AboutTour: React.FC = () => {
  const { language } = useLanguage();

  return (
    <PageContainer>
      <BannerSection>About Tour</BannerSection>

      <TourContainer>
        {tourData[language].map((tour, index) => (
          <TourBlock key={index} reverse={index % 2 !== 0}>
            <TourImage bgImage={tour.image} />
            <TourText>
              <MobileImageBackground bgImage={tour.image} />
              <h2>{tour.title}</h2>
              <p>{tour.description}</p>
            </TourText>
          </TourBlock>
        ))}
      </TourContainer>
    </PageContainer>
  );
};

export default AboutTour;
